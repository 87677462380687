

































import { computed, defineComponent, PropType, ref, toRefs, watch } from '@nuxtjs/composition-api'
import { AnimationItem } from 'lottie-web'

import heart from '~/assets/heart.json'

export default defineComponent({
  props: {
    liked: { type: Boolean, default: false },
    animated: { type: Boolean, default: true },
    color: { type: String, default: 'tw-text-red' },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
      validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
    animationData: { type: Object, default: () => heart },
  },
  setup(props, { emit }) {
    const { liked, animated, size } = toRefs(props)
    const defaultOptions = { animationData: heart, loop: false, autoplay: true, width: 80, height: 80, playSpeed: 0.1 }
    const animation = ref<AnimationItem | null>(null)
    const lottieLoaded = ref(false)
    const iCanInitLotti = computed(() => liked.value && !!animation.value && animated.value)

    const handleAnimation = (anim: AnimationItem) => {
      animation.value = anim
      initStateAnim()
    }

    const initStateAnim = () => {
      if (!animation.value) {
        return
      }

      !liked.value ? animation.value?.stop() : animation.value?.play()
    }

    const handelAnimLoaded = () => {
      lottieLoaded.value = true
    }

    const handelAnimError = () => {
      lottieLoaded.value = false
    }

    watch(
      iCanInitLotti,
      () => {
        initStateAnim()
      },
      { immediate: true }
    )

    const toggleLike = () => {
      liked.value ? animation.value?.stop() : animation.value?.play()
      emit('toggleLike')
    }

    const getSize = computed(() => {
      switch (size.value) {
        case 'xs':
          return 30
        case 'sm':
          return 40
        case 'md':
          return 60
        case 'lg':
          return 70
        case 'xl':
          return 80
        default:
          return 60
      }
    })

    return {
      getSize,
      lottieLoaded,
      toggleLike,
      defaultOptions,
      handleAnimation,
      handelAnimLoaded,
      handelAnimError,
    }
  },
})
