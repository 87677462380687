







import { defineComponent, toRefs, ref, onMounted } from '@nuxtjs/composition-api'
import lottie from 'lottie-web'

export default defineComponent({
  props: {
    options: { type: Object, required: true },
    height: { type: Number, default: 0 },
    width: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    const lottieContainer = ref()
    const { width, height, options } = toRefs(props)

    onMounted(() => {
      const anim = ref(lottie.loadAnimation({
        container: lottieContainer.value,
        renderer: 'svg',
        loop: options.value.loop !== false,
        autoplay: options.value.autoplay !== false,
        animationData: options.value.animationData,
        rendererSettings: options.value.rendererSettings,
      }))
      anim.value.addEventListener('DOMLoaded', () => {
        emit('animLoaded', anim.value)
      })
      anim.value.addEventListener('error', () => {
        emit('animError', anim.value)
      })
      anim.value.addEventListener('destroy', () => {
        anim.value.removeEventListener('DOMLoaded')
        anim.value.removeEventListener('error')
      })
      emit('animCreated', anim.value)
    })

    return {
      lottieContainer,
      style: {
        width: width.value ? `${width.value}px` : '100%',
        height: height.value ? `${height.value}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto',
      },
    }
  },
})
